<template>
	<ul class="nav">
		<li class="nav-profile">
			<a href="javascript:;" v-on:click="expand()">
				<div class="cover with-shadow"></div>
				<div class="image image-icon bg-black text-grey-darker">
					<img src="/assets/logo_round.png" alt="" height="50" width="50">
				</div>
				<!--
				<div class="image image-icon bg-black text-grey-darker">
					<i class="fa fa-user"></i>
				</div>
				-->
				<div class="info">
					<b class="caret pull-right"></b>
					{{userInfo && userInfo.name ? userInfo.name : appName}}
				</div>
			</a>
		</li>
		<li>
			<ul class="nav nav-profile" v-bind:class="{ 'd-block': this.stat == 'expand' && !this.pageOptions.pageSidebarMinified, 'd-none': this.stat == 'collapse' }">
				<li><a href="javascript:;"><i class="fa fa-pencil-alt"></i> Feedback</a></li>
				<li><a href="javascript:;"><i class="fa fa-question-circle"></i> Aide</a></li>
			</ul>
		</li>
	</ul>
</template>

<script>
import PageOptions from '../../config/PageOptions.vue'
import LocalStorage from '../../store/local-storage'

export default {
	name: 'SidebarNavProfile',
    computed: {
    },
	methods: {
		expand: function() {
			this.stat = (this.stat == 'expand') ? 'collapse' : 'expand'
		}
  },
    data() {
        return {
            stat: '',
            pageOptions: PageOptions,
            appName : window.appConfig.appName,
            appCurrency: window.appConfig.appCurrency,
            userInfo: null,
        }
    },
    created() {
        this.userInfo = LocalStorage.getUserInfo() ;
    }
}
</script>
