<script>
const sidebarMenu = [
  {
    path: '/dashboard', icon: 'fa fa-tachometer-alt', title: 'Dashboard',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/vaults', icon: 'fa fa-dollar-sign', title: 'Caisse & Dépenses',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/scm', icon: 'fa fa-shopping-basket', title: 'Appro. & Achats',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/production', icon: 'fa fa-flask', title: 'Usine & Production',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/stock', icon: 'fa fa-cube', title: 'Stock & Inventaires',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/prospection', icon: 'fa fa-map', title: 'Prospection',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/sales', icon: 'fa fa-chart-bar', title: 'Vente & Facturation',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/monitoring', icon: 'fa fa-tv', title: 'Suivi & Contrôle',
    //children: [{path: '/dashboard', title: 'Main'},]
  },
  {
    path: '/admin', icon: 'fas fa-cogs', title: 'Console Admin.',
  },
  /*
  {
      path: '/playground', icon: 'fab fa-stack-overflow', title: 'Playground',
      //children: [{path: '/dashboard', title: 'Main'},]
  },
  */
];

export default sidebarMenu;
</script>
