<template>
    <div>
        <!-- begin login-cover -->
        <div class="login-cover">
            <div class="login-cover-image" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
            <div class="login-cover-bg"></div>
        </div>
        <!-- end login-cover -->

        <!-- begin login -->
        <div class="login login-v2" data-pageload-addclass="animated fadeIn">
            <!-- begin brand -->
            <div class="login-header">
                <img src="/assets/logo.png" width="150" height="150"/>
                <div class="brand">
                    <!--<span class="logo"></span>-->
                    <strong>Code secret oublié?</strong><br/>
                    <p class="lead">Vérifions ensemble votre identité</p>
                </div>

                <div class="m-t-30 text-center text-white">
                    <p class="f-s-17">Veuillez saisir le numéro de téléphone enregistré sur votre compte.</p>
                </div>
            </div>
            <!-- end brand -->
            <!-- begin login-content -->
            <div class="login-content">

                <div>
                    <div class="alert alert-success fade show" v-if="phoneVerifyResponse.meta.successMessage">
                        <strong>Bravo!</strong>
                        {{phoneVerifyResponse.meta.successMessage}}
                    </div>
                    <div class="alert alert-warning fade show" v-if="phoneVerifyResponse.meta.warningMessage">
                        <strong>Attention!</strong>
                        {{phoneVerifyResponse.meta.warningMessage}}
                    </div>

                    <div class="alert alert-danger fade show" v-if="phoneVerifyResponse.meta.errorMessage">
                        <strong>Oops!</strong>
                        {{phoneVerifyResponse.meta.errorMessage}}
                    </div>
                </div>

                <form @submit.prevent="verify(formData)">
                    <div class="form-group m-b-20">
                        <input type="text" class="form-control form-control-lg" placeholder="Numéro de téléphone" required v-model="formData.phone"  :class="{ 'is-invalid': phoneVerifyResponse.meta.validationErrors.phone }">
                        <div class="text-danger" v-if="phoneVerifyResponse.meta.validationErrors.phone">{{phoneVerifyResponse.meta.validationErrors.phone[0]}}</div>
                    </div>


                    <div class="m-t-40 login-buttons">
                        <button type="submit" class="btn btn-green btn-block btn-lg" :disabled="phoneVerifyResponse.meta.isLoading"> <i class="fa-2x fas fa-spinner fa-pulse text-white" v-if="phoneVerifyResponse.meta.isLoading"></i> <span v-else>VERIFIER</span> </button>
                    </div>

                    <div class="m-t-20 text-center f-s-16">
                        <strong>Fausse manoeuvre? <a   class="text-orange" v-on:click="navigateToLogin">Retour</a></strong>
                    </div>

                    <div class="m-t-20 text-center f-s-16">
                        <strong><router-link to="/" class="text-orange" > Retourner a l'accueil </router-link></strong>
                    </div>

                    <!--
                    <div class="m-t-20 text-center f-s-16">
                        <strong>Besoin d'aide? <a   class="text-orange" v-on:click="navigateToContact">Contactez-nous</a></strong>
                    </div>
                    -->

                </form>

            </div>
            <!-- end login-content -->
        </div>
        <!-- end login -->

        <!-- begin login-bg -->
        <ul class="login-bg-list clearfix">
            <li v-bind:class="{ 'active': bg.bg1.active }"><a  v-on:click="select('bg1')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-17.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg2.active }"><a  v-on:click="select('bg2')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-16.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg3.active }"><a  v-on:click="select('bg3')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg4.active }"><a  v-on:click="select('bg4')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-14.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg5.active }"><a  v-on:click="select('bg5')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-13.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg6.active }"><a  v-on:click="select('bg6')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-12.jpg)"></a>
            </li>
        </ul>
        <!-- end login-bg -->
    </div>
</template>

<script>
    import PageOptions from '../../config/PageOptions.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: 'verify-page-component',
        components: {
        },
        props: {
            msg: {
                type: String,
                default: 'DigiBOX'
            }
        },
        computed: {
            ...mapGetters('AuthModule', {
                phoneVerifyResponse: 'phoneVerifyResponse'
            }),
        },
        methods: {
            ...mapActions('AuthModule', {
                verify: 'verify'
            }),

            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
            select: function (x) {
                this.bg.bg1.active = false;
                this.bg.bg2.active = false;
                this.bg.bg3.active = false;
                this.bg.bg4.active = false;
                this.bg.bg5.active = false;
                this.bg.bg6.active = false;

                switch (x) {
                    case 'bg1':
                        this.bg.bg1.active = true;
                        this.bg.activeImg = this.bg.bg1.img;
                        break;
                    case 'bg2':
                        this.bg.bg2.active = true;
                        this.bg.activeImg = this.bg.bg2.img;
                        break;
                    case 'bg3':
                        this.bg.bg3.active = true;
                        this.bg.activeImg = this.bg.bg3.img;
                        break;
                    case 'bg4':
                        this.bg.bg4.active = true;
                        this.bg.activeImg = this.bg.bg4.img;
                        break;
                    case 'bg5':
                        this.bg.bg5.active = true;
                        this.bg.activeImg = this.bg.bg5.img;
                        break;
                    case 'bg6':
                        this.bg.bg6.active = true;
                        this.bg.activeImg = this.bg.bg6.img;
                        break;
                }
            },

            navigateToLogin : function () {
                this.$router.replace({
                    name : 'login'
                })
            },

            navigateToContact : function () {
                this.$router.replace({
                    name : 'contacts'
                })
            }
        },
        data: function () {
            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,
                formData: {
                    phone: '',
                    pin_code: ''
                },
                bg: {
                    activeImg: '../../assets/img/login-bg/login-bg-13.jpg',
                    bg1: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-17.jpg'
                    },
                    bg2: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-16.jpg'
                    },
                    bg3: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-15.jpg'
                    },
                    bg4: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-14.jpg'
                    },
                    bg5: {
                        active: true,
                        img: '../../assets/img/login-bg/login-bg-13.jpg'
                    },
                    bg6: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-12.jpg'
                    }
                }
            };
        },
        created: function () {
            PageOptions.pageEmpty = true;
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
        beforeRouteLeave(to, from, next) {
            PageOptions.pageEmpty = false;
            next();
        },
    }
</script>