/**
 * Here are all the Backend api calls for our modules
 */

export default {

    //APP NAME
    getAppName() {
        return localStorage.getItem("app_name");
    },

    setAppName(name) {
        localStorage.setItem("app_name", name);
    },

    hasAppName() {
        return localStorage.getItem("app_name") !== null;
    },

    clearAppName() {
        localStorage.removeItem("app_name");
    },

    //API TOKEN
    getApiToken() {
        return localStorage.getItem("api_token");
    },

    setApiToken(token) {
        localStorage.setItem("api_token", token);
    },

    hasApiToken() {
        return localStorage.getItem("api_token") !== null;
    },

    clearApiToken() {
        localStorage.removeItem("api_token");
    },
    
    //
    getApiTokenHeaderData() {
        if (localStorage.getItem("api_token") !== null) {
            return "Bearer " + localStorage.getItem("api_token");
        } else {
            return "Bearer ";
        }
    },

    //OTHER
    createSession(loginResponse) {
        localStorage.setItem("app_name", loginResponse.app_name);
        localStorage.setItem("api_token", loginResponse.access_token);
        //
        localStorage.setItem("user_company_id", loginResponse.user_info.company_id);
        localStorage.setItem("user_id", loginResponse.user_info.id);
        localStorage.setItem("user_name", loginResponse.user_info.name);
        localStorage.setItem("user_phone", loginResponse.user_info.phone);
        localStorage.setItem("user_email", loginResponse.user_info.email);
    },

    clearSessionData() {
        localStorage.removeItem("app_name");
        localStorage.removeItem("api_token");
        //
        localStorage.removeItem("user_company_id");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_phone");
        localStorage.removeItem("user_email");
    },

    getUserInfo() {
        return {
            id: localStorage.getItem("user_id"),
            name: localStorage.getItem("user_name"),
            phone: localStorage.getItem("user_phone"),
            email: localStorage.getItem("user_email"),
            company_id: localStorage.getItem("user_company_id")
        };
    },

    clearAllData() {
        localStorage.clear();
    },
}