/**
 * Here are all the Backend api calls for our modules
 */
import appConfig from '../../config/AppConfig'
import authApiClient from './auth-api-client'

export default {
    //Auth
    login(phone, pinCode) {
        return authApiClient().post('/login', {
            phone: phone,
            pin_code: pinCode,
            client_app_name: appConfig.apiClientAppName
        });
    },
    verify(phone) {
        return authApiClient().post('/verify', {
            phone: phone,
            client_app_name: appConfig.apiClientAppName
        });
    },
    otp(phone, smsCode, pinCode) {
        return authApiClient().post('/otp', {
            phone: phone,
            sms_code: smsCode,
            pin_code: pinCode,
            client_app_name: appConfig.apiClientAppName
        });
    },
    register(name, phone) {
        return authApiClient().post('/register', {
            name: name,
            phone: phone,
            client_app_name: appConfig.apiClientAppName
        });
    },
    registrationOtp(name, phone, smsCode, pinCode) {
        return authApiClient().post('/registration-otp', {
            name: name,
            phone: phone,
            sms_code: smsCode,
            pin_code: pinCode,
            client_app_name: appConfig.apiClientAppName
        });
    },
    logout() {
        return authApiClient().get('/logout');
    },
}