<template>
    <div>
        <div class="login-cover">
            <div class="login-cover-image" style="background-image: url(/assets/img/login-bg/login-bg-7.jpg)"></div>
            <div class="login-cover-bg"></div>
        </div>

        <div class="login login-v2" data-pageload-addclass="animated fadeIn">
            <div class="login-header  text-center">
                <img src="/assets/logo.png" width="150" height="150"/>
                <div class="brand">
                    <!--<span class="logo"></span>-->
                    <strong>{{appName}}</strong><br/>
                    <router-link to="/dashboard" class="btn btn-green m-r-10" >  <strong>ALLER AU TABLEAU DE BORD</strong> </router-link>
                    <!--
                    <router-link to="/about" class="btn btn-green m-r-10" > A Propos </router-link>
                    <router-link to="/contacts" class="btn btn-green m-r-10" > Contacts </router-link>
                    <router-link to="/terms-and-conditions" class="btn btn-green m-r-10" > Termes & Conditions </router-link>
                    -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PageOptions from '../config/PageOptions.vue'

    export default {
        name: 'home-page-component',
        components: {
        },
        props: {},
        watch: {},
        computed: {},
        methods: {
            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
        },
        data: function () {
            return {
                appName : window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,
            };
        },
        created: function () {
            PageOptions.pageEmpty = true;
        },
        mounted: function () {
            this.loadPageScripts();
        },
        beforeDestroy: function () {
        },
        beforeRouteLeave(to, from, next) {
            PageOptions.pageEmpty = false;
            next();
        },
    }
</script>
