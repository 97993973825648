<script>
const pageOptions = {
  pageSidebarMinified: true,
  pageContentFullWidth: false,
  pageContentInverseMode: false,
  pageContentFullHeight: false,
  pageWithLanguageBar: false,
  pageWithFooter: false,
  pageWithoutSidebar: true,
  pageWithRightSidebar: false,
  pageWithTwoSidebar: false,
  pageWithWideSidebar: false,
  pageWithLightSidebar: false,
  pageWithMegaMenu: false,
  pageWithTopMenu: true,
  pageSidebarTransparent: false,
  pageEmpty: false,
  pageMobileSidebarToggled: false,
  pageMobileRightSidebarToggled: false,
  pageMobileTopMenu: false,
  pageMobileMegaMenu: false,
  pageRightSidebarToggled: false,
  pageRightSidebarCollapsed: false,
  pageSidebarSearch: false,
  pageBodyScrollTop: 0
};

export default pageOptions;
</script>