/**
 * Here is our app Main Store
 */
import Vue from 'vue';
import Vuex from 'vuex'
import AuthModule from './modules/auth'

//Load Vuex
Vue.use(Vuex);

//Create Store
const store = new Vuex.Store({
    modules:{
        AuthModule,
    }
});

export default store;

