import api from '../api/auth-api'
import LocalStorage from '../../store/local-storage'
import router from '../../main'


const namespaced = true;

const state = {
    loginResponse: {
        meta: {
            isLoading: false,
            successMessage: null,
            warningMessage: null,
            errorMessage: null,
            validationErrors: []
        },
        data: null
    },
    phoneVerifyResponse: {
        meta: {
            isLoading: false,
            successMessage: null,
            warningMessage: null,
            errorMessage: null,
            validationErrors: []
        },
        data: null
    },
    logoutResponse: {
        meta: {
            isLoading: false,
            successMessage: null,
            warningMessage: null,
            errorMessage: null,
            validationErrors: []
        },
        data: null
    },
};

const getters = {
    loginResponse: (state) => {
        return state.loginResponse
    },
    phoneVerifyResponse: (state) => {
        return state.phoneVerifyResponse
    },
    logoutResponse: (state) => {
        return state.logoutResponse
    }
};

const mutations = {
    setLoginResponse: (state, data) => {
        state.loginResponse = data
    },
    setPhoneVerifyResponse: (state, data) => {
        state.phoneVerifyResponse = data
    },
    setLogoutResponse: (state, data) => {
        state.logoutResponse = data
    }
};

const actions = {
    async login({commit}, formData) {
        commit('setLoginResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.login(formData.phone, formData.pin_code);
            //console.log(response);
            commit('setLoginResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });

            LocalStorage.createSession(response.data);
            window.location.reload();
            router.replace({
                name: 'dashboard'
            });

        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setLoginResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    },

    async verify({commit}, formData) {
        commit('setPhoneVerifyResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.verify(formData.phone);
            //console.log(response);
            commit('setPhoneVerifyResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });
            router.replace(
                {
                    name: 'otp',
                    params: {
                        name: response.data.full_name,
                        phone: response.data.phone
                    }
                }
            );
        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setPhoneVerifyResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    },

    async otp({commit}, formData) {
        commit('setLoginResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.otp(formData.phone, formData.sms_code, formData.pin_code);
            //console.log(response);
            commit('setLoginResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });

            LocalStorage.createSession(response.data);
            window.location.reload();
            router.replace({
                name: 'dashboard'
            });

        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setLoginResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    },

    async register({commit}, formData) {
        commit('setPhoneVerifyResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.register(formData.name, formData.phone);
            //console.log(response);
            commit('setPhoneVerifyResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });

            router.replace(
                {
                    name: 'registration-otp',
                    params: {
                        name: response.data.full_name,
                        phone: response.data.phone
                    }
                }
            );

        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setPhoneVerifyResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setPhoneVerifyResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    },

    async registrationOtp({commit}, formData) {
        commit('setLoginResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.registrationOtp(formData.name, formData.phone, formData.sms_code, formData.pin_code);
            //console.log(response);
            commit('setLoginResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });

            LocalStorage.createSession(response.data);
            window.location.reload();
            router.replace({
                name: 'dashboard'
            });

        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setLoginResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setLoginResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    },

    async logout({commit}) {
        commit('setLogoutResponse', {
            meta: {
                isLoading: true,
                successMessage: null,
                warningMessage: null,
                errorMessage: null,
                validationErrors: []
            },
            data: null
        });
        try {
            const response = await api.logout();
            //console.log(response);
            commit('setLogoutResponse', {
                meta: {
                    isLoading: false,
                    successMessage: null,
                    warningMessage: null,
                    errorMessage: null,
                    validationErrors: []
                },
                data: response.data
            });

            LocalStorage.clearSessionData();
            router.replace({
                name: 'home'
            });

        } catch (error) {
            if (error.response) {
                //Server responded with a status code that is NOT in the HTTP 200 range
                //console.log("Headers", error.response.headers);
                //console.log("Status Code", error.response.status);
                //console.log("Response Data", error.response.data);
                if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
                    commit('setLogoutResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });



                } else if (error.response.status === 422) {
                    commit('setLogoutResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: error.response.data.errors
                        },
                        data: null
                    });
                } else {
                    commit('setLogoutResponse', {
                        meta: {
                            isLoading: false,
                            successMessage: null,
                            warningMessage: null,
                            errorMessage: error.response.data.message,
                            validationErrors: []
                        },
                        data: null
                    });
                }
            } else {
                commit('setLogoutResponse', {
                    meta: {
                        isLoading: false,
                        successMessage: null,
                        warningMessage: null,
                        errorMessage: error.message,
                        validationErrors: []
                    },
                    data: null
                });
            }
        }
    }
};


export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
