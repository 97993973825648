<template>
    <div>
        <!-- begin login-cover -->
        <div class="login-cover">
            <div class="login-cover-image" v-bind:style="{ backgroundImage: 'url('+ bg.activeImg +')' }"></div>
            <div class="login-cover-bg"></div>
        </div>
        <!-- end login-cover -->

        <!-- begin login -->
        <div class="login login-v2" data-pageload-addclass="animated fadeIn">
            <!-- begin brand -->
            <div class="login-header">
                <img src="/assets/logo.png" width="150" height="150"/>
                <div class="brand">
                    <!--<span class="logo"></span>-->
                    <strong>SMS envoyé!</strong><br/>
                    <p class="lead">Code de vérification envoyé par SMS</p>
                </div>

                <div class="m-t-20 text-center text-white">
                    <p class="f-s-15">Nous avons envoyé un SMS contenant un code de vérification au numéro de téléphone
                        ci-dessous.</p>
                    <div class="brand">
                        <strong>{{formData.phone}}</strong><br/>
                        <p class="lead">{{formData.name}}</p>
                    </div>
                    <div class="m-t-10 text-center f-s-16">
                        <strong>Pas le bon numéro? <a  class="text-orange" v-on:click="navigateToVerify">Modifier</a></strong>
                    </div>
                </div>
            </div>
            <!-- end brand -->
            <!-- begin login-content -->
            <div class="login-content">

                <div>
                    <div class="alert alert-success fade show" v-if="loginResponse.meta.successMessage">
                        <strong>Bravo!</strong>
                        {{loginResponse.meta.successMessage}}
                    </div>
                    <div class="alert alert-warning fade show" v-if="loginResponse.meta.warningMessage">
                        <strong>Attention!</strong>
                        {{loginResponse.meta.warningMessage}}
                    </div>

                    <div class="alert alert-danger fade show" v-if="loginResponse.meta.errorMessage">
                        <strong>Oops!</strong>
                        {{loginResponse.meta.errorMessage}}
                    </div>
                </div>

                <form @submit.prevent="otp(formData)">
                    <div class="form-group m-b-20">
                        <input type="text" class="form-control form-control-lg" placeholder="Code de vérification SMS"
                               required v-model="formData.sms_code"
                               :class="{ 'is-invalid': loginResponse.meta.validationErrors.sms_code }"/>
                        <div class="text-danger" v-if="loginResponse.meta.validationErrors.sms_code">
                            {{loginResponse.meta.validationErrors.sms_code[0]}}
                        </div>
                    </div>
                    <div class="form-group m-b-20">
                        <input type="text" class="form-control form-control-lg"
                               placeholder="Saisir un code secret personnel (4 Chiffres)" required
                               v-model="formData.pin_code"
                               :class="{ 'is-invalid': loginResponse.meta.validationErrors.pin_code }"/>
                        <div class="text-danger" v-if="loginResponse.meta.validationErrors.pin_code">
                            {{loginResponse.meta.validationErrors.pin_code[0]}}
                        </div>
                    </div>

                    <div class="m-t-40 login-buttons">
                        <button type="submit" class="btn btn-green btn-block btn-lg"
                                :disabled="loginResponse.meta.isLoading"><i
                                class="fa-2x fas fa-spinner fa-pulse text-white"
                                v-if="loginResponse.meta.isLoading"></i> <span v-else>ENREGISTRER</span></button>
                    </div>

                    <div class="m-t-20 text-center f-s-16" v-if="timer > 0">
                        <strong>Renvoi SMS dans <a  class="text-orange">00:{{timer >= 10 ? timer : '0' + timer}}</a></strong>
                    </div>
                    <div class="m-t-20 text-center f-s-16" v-else>
                        <strong>SMS non recu? <a  class="text-orange" v-on:click="navigateToVerify">Renvoyer</a></strong>
                    </div>

                </form>

                <!--
                <div class="m-t-20 text-center">
                    <a  class="btn width-250 btn-social btn-google">
                        <span class="fab fa-google"></span> Connexion via Google
                    </a>
                    <a  class="btn width-250 btn-social btn-facebook">
                        <span class="fab fa-facebook"></span> Connexion via Facebook
                    </a>
                </div>
                -->

            </div>
            <!-- end login-content -->
        </div>
        <!-- end login -->

        <!-- begin login-bg -->
        <ul class="login-bg-list clearfix">
            <li v-bind:class="{ 'active': bg.bg1.active }"><a  v-on:click="select('bg1')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-17.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg2.active }"><a  v-on:click="select('bg2')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-16.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg3.active }"><a  v-on:click="select('bg3')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-15.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg4.active }"><a  v-on:click="select('bg4')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-14.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg5.active }"><a  v-on:click="select('bg5')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-13.jpg)"></a>
            </li>
            <li v-bind:class="{ 'active': bg.bg6.active }"><a  v-on:click="select('bg6')"
                                                              style="background-image: url(/assets/img/login-bg/login-bg-12.jpg)"></a>
            </li>
        </ul>
        <!-- end login-bg -->
    </div>
</template>

<script>
    import PageOptions from '../../config/PageOptions.vue'
    import {mapGetters, mapActions} from 'vuex';

    export default {
        name: 'otp-page-component',
        components: {},
        props: {
            msg: {
                type: String,
                default: 'DigiBOX'
            }
        },
        computed: {
            ...mapGetters('AuthModule', {
                loginResponse: 'loginResponse'
            }),
        },
        methods: {
            ...mapActions('AuthModule', {
                otp: 'otp'
            }),

            //Initialization Methods
            loadPageScripts() {
            },

            //Other Methods
            navigateToVerify: function () {
                this.$router.replace({
                    name: 'verify'
                })
            },

            resendSMS: function () {

            },


            startInterval: function(){
                this.intervalId01 = setInterval(() => {
                    if(this.timer >= 1) {
                        this.timer = this.timer - 1;
                    }
                }, 1000);
            },

            select: function (x) {
                this.bg.bg1.active = false;
                this.bg.bg2.active = false;
                this.bg.bg3.active = false;
                this.bg.bg4.active = false;
                this.bg.bg5.active = false;
                this.bg.bg6.active = false;

                switch (x) {
                    case 'bg1':
                        this.bg.bg1.active = true;
                        this.bg.activeImg = this.bg.bg1.img;
                        break;
                    case 'bg2':
                        this.bg.bg2.active = true;
                        this.bg.activeImg = this.bg.bg2.img;
                        break;
                    case 'bg3':
                        this.bg.bg3.active = true;
                        this.bg.activeImg = this.bg.bg3.img;
                        break;
                    case 'bg4':
                        this.bg.bg4.active = true;
                        this.bg.activeImg = this.bg.bg4.img;
                        break;
                    case 'bg5':
                        this.bg.bg5.active = true;
                        this.bg.activeImg = this.bg.bg5.img;
                        break;
                    case 'bg6':
                        this.bg.bg6.active = true;
                        this.bg.activeImg = this.bg.bg6.img;
                        break;
                }
            }
        },
        data: function () {
            return {
                appName: window.appConfig.appName,
                appCurrency: window.appConfig.appCurrency,
                intervalId01: 0,
                timer: 60,
                formData: {
                    name: this.$route.params.name,
                    phone: this.$route.params.phone,
                    sms_code: '',
                    pin_code: ''
                },
                bg: {
                    activeImg: '../../assets/img/login-bg/login-bg-15.jpg',
                    bg1: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-17.jpg'
                    },
                    bg2: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-16.jpg'
                    },
                    bg3: {
                        active: true,
                        img: '../../assets/img/login-bg/login-bg-15.jpg'
                    },
                    bg4: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-14.jpg'
                    },
                    bg5: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-13.jpg'
                    },
                    bg6: {
                        active: false,
                        img: '../../assets/img/login-bg/login-bg-12.jpg'
                    }
                }
            };
        },
        created: function () {
            PageOptions.pageEmpty = true;
        },
        mounted: function () {
            this.loadPageScripts();
            this.startInterval();
        },
        beforeDestroy: function () {
            clearInterval(this.intervalId01)
        },
        beforeRouteLeave(to, from, next) {
            PageOptions.pageEmpty = false;
            next();
        },
    }
</script>